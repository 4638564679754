// Copyright 2017-2021 Dgraph Labs, Inc. and Contributors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// Public domain image from https://commons.wikimedia.org/wiki/File:Santa_hat.svg

import React from "react";

export default function SantaHat() {
    return (
        <svg x="0" y="0" width="100%" height="100%" viewBox="0 0 411 286">
            <g id="layer1" transform="translate(-8.9025329,-24.385462)">
                <path
                    d="M 234.10,28.78 C 206.00,36.51 177.19,51.89 156.93,66.78 C 136.67,81.68 83.74,119.20 71.66,131.09 C 59.94,142.62 46.10,146.64 40.05,160.85 C 37.71,166.33 55.88,182.85 59.44,184.57 C 68.68,189.02 83.75,181.13 90.62,180.65 C 112.93,179.12 135.79,176.16 158.38,191.45 C 154.20,195.67 160.84,191.12 152.29,228.14 C 149.28,241.19 142.70,275.06 150.79,281.52 C 158.83,287.94 163.74,262.92 171.72,266.75 C 171.72,266.75 171.72,266.77 171.72,266.77 C 171.72,266.78 171.72,266.80 171.71,266.80 C 171.72,266.80 171.71,266.82 171.71,266.83 C 171.71,266.83 171.73,266.83 171.74,266.83 C 171.76,266.84 171.81,266.83 171.86,266.83 C 171.88,266.84 171.90,266.83 171.91,266.84 C 171.97,266.87 172.02,266.90 172.08,266.93 L 172.13,266.80 C 175.91,265.82 205.39,252.83 232.30,258.05 C 247.47,261.00 271.67,252.75 285.59,248.44 C 307.67,241.61 338.66,235.44 358.32,235.09 C 372.80,234.83 385.91,226.63 399.84,225.67 C 408.06,225.11 400.44,198.61 400.27,195.81 C 399.45,182.35 382.70,161.68 374.08,143.30 C 365.45,124.93 351.00,101.61 335.07,83.32 C 319.15,65.03 297.67,41.35 282.55,34.06 C 267.43,26.77 264.60,20.38 234.10,28.78 z "
                    style={{
                        fill: "#d00000",
                        fillOpacity: 1,
                        fillRule: "evenodd",
                        stroke: "#000",
                        strokeWidth: 0.8,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                    }}
                />
                <path
                    d="M 166.95,93.34 C 148.72,97.76 133.10,156.03 110.63,166.16 C 95.93,172.79 55.88,182.85 59.44,184.57 C 68.68,189.02 83.75,181.13 90.62,180.65 C 112.93,179.12 135.79,176.16 158.38,191.45 C 154.20,195.67 160.84,191.12 152.29,228.14 C 149.28,241.19 142.70,275.06 150.79,281.52 C 158.83,287.94 163.74,262.92 171.72,266.75 C 171.72,266.75 171.72,266.77 171.72,266.77 C 171.72,266.78 171.72,266.80 171.71,266.80 C 171.72,266.80 171.71,266.82 171.71,266.83 C 171.71,266.83 171.73,266.83 171.74,266.83 C 171.76,266.84 171.81,266.83 171.86,266.83 C 171.88,266.84 171.90,266.83 171.91,266.84 C 171.97,266.87 172.02,266.90 172.08,266.93 L 172.13,266.80 C 175.91,265.82 205.39,252.83 232.30,258.05 C 247.47,261.00 271.67,252.75 285.59,248.44 C 307.67,241.61 338.66,235.44 358.32,235.09 C 372.80,234.83 385.91,226.63 399.84,225.67 C 408.06,225.11 348.76,230.19 372.39,213.10 C 382.88,205.51 267.41,228.91 223.08,178.32 C 179.18,128.23 185.62,88.82 166.95,93.34 z "
                    style={{
                        opacity: 0.1,
                        fill: "#0e0000",
                        fillOpacity: 1,
                        fillRule: "evenodd",
                        stroke: "none",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                    }}
                />
                <path
                    d="M 41.92,154.63 C 45.47,156.98 69.32,187.14 72.13,185.03 C 72.47,181.70 69.69,180.39 67.74,180.01 C 70.36,176.68 70.85,173.20 66.30,172.90 C 61.26,172.01 55.76,171.39 61.17,165.96 C 63.67,162.93 61.00,157.53 59.47,154.79 C 55.44,152.61 52.40,148.67 50.75,145.99876"
                    style={{
                        opacity: 0.26,
                        fill: "#000",
                        fillOpacity: 1,
                        fillRule: "evenodd",
                        stroke: "#000",
                        strokeWidth: 0.8,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 4,
                    }}
                />
                <path
                    d="M 365.59,219.58 C 358.14,216.94 348.79,213.89 342.20,214.29 C 337.68,210.47 334.20,215.18 330.28,216.44 C 329.63,209.08 318.77,219.55 313.99,219.61 C 310.60,221.56 312.94,213.81 306.86,216.75 C 299.30,217.47 294.63,217.99 289.02,220.42 C 287.67,228.43 275.57,232.94 273.76,224.16 C 267.16,227.03 254.89,232.60 254.81,223.52 C 244.25,231.71 243.91,239.12 233.60,243.07 C 234.51,235.98 211.23,232.16 208.05,239.61 C 201.66,242.18 209.38,230.29 200.15,233.55 C 189.75,230.53 195.80,251.49 174.60,242.92 C 162.79,242.54 169.33,259.64 161.53,266.80 C 159.46,274.33 150.45,264.18 146.80,264.74 C 147.65,276.67 150.21,272.62 147.52,280.74 C 145.92,290.90 143.77,295.99 154.96,298.11 C 158.58,293.21 154.77,295.28 155.58,304.33 C 162.04,302.45 170.53,291.69 169.66,302.75 C 174.04,296.07 187.91,292.60 187.74,285.41 C 194.76,286.15 202.64,293.99 208.57,284.46 C 214.52,277.41 223.07,274.15 223.16,279.59 C 230.40,286.34 231.12,284.97 238.64,283.25 C 236.70,274.54 256.24,282.66 245.92,273.98 C 250.02,269.88 261.11,267.48 267.52,268.91 C 273.19,277.16 290.46,277.84 293.15,267.16 C 293.72,258.76 306.83,255.88 311.74,261.69 C 320.27,254.21 333.72,265.08 341.06,259.35 C 334.47,250.78 350.39,245.19 353.36,251.75 C 359.20,248.62 364.64,237.33 371.03,245.41 C 378.38,243.02 387.35,239.69 393.57,237.19 C 398.24,244.88 410.32,236.97 405.08,227.66 C 402.25,221.01 414.39,230.93 411.09,222.49 C 407.32,213.42 402.04,200.80 395.57,201.22 C 386.76,199.02 381.53,204.96 381.17,213.38 C 381.06,207.24 370.65,213.22 365.18,215.45 L 362.19,216.16 L 358.61,213.6461"
                    style={{
                        opacity: 0.29,
                        fill: "#000",
                        fillOpacity: 1,
                        fillRule: "evenodd",
                        stroke: "#000",
                        strokeWidth: 0.6,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                    }}
                />
                <path
                    d="M 370.52,221.83 C 363.07,219.18 356.16,218.81 349.57,219.22 C 345.05,215.40 339.68,216.65 335.76,217.90 C 335.10,210.54 326.14,224.47 321.36,224.53 C 317.97,226.48 320.31,218.73 314.23,221.68 C 306.67,222.39 297.91,217.71 292.31,220.13 C 290.96,228.15 278.86,232.66 277.04,223.88 C 270.45,226.75 262.26,237.53 262.18,228.44 C 249.80,227.90 244.49,240.08 234.18,244.03 C 235.09,236.94 218.60,237.09 215.42,244.53 C 209.04,247.10 213.73,231.09 204.49,234.36 C 194.09,231.34 194.38,252.06 181.97,247.85 C 170.16,247.47 171.81,259.20 164.02,266.37 C 161.94,273.90 157.82,269.10 154.18,269.67 C 160.18,278.18 135.78,276.41 143.02,289.54 C 141.42,299.70 143.87,307.01 155.06,309.13 C 158.68,304.22 162.15,300.21 162.96,309.25 C 169.41,307.37 177.90,296.61 177.03,307.67 C 181.41,301.00 195.28,297.52 195.11,290.33 C 202.13,291.07 210.01,298.91 215.94,289.38 C 221.89,282.34 222.87,288.08 222.96,293.52 C 230.20,300.28 238.49,289.90 246.01,288.18 C 244.07,279.47 263.61,287.58 253.29,278.91 C 257.39,274.80 268.48,272.41 274.89,273.83 C 280.51,279.17 297.84,282.77 300.52,272.09 C 301.09,263.68 314.21,260.81 319.11,266.62 C 327.64,259.14 341.09,270.01 348.43,264.27 C 341.84,255.71 357.76,250.11 360.73,256.68 C 366.57,253.54 372.01,242.26 378.40,250.34 C 385.75,247.94 394.73,244.61 400.94,242.12 C 405.61,249.81 417.69,241.89 412.45,232.58 C 409.62,225.93 421.76,235.86 418.46,227.42 C 418.53,217.76 414.89,202.82 402.94,206.14 C 394.13,203.94 388.91,209.89 388.54,218.30 C 388.43,212.16 374.51,214.38 369.05,216.61 L 367.26,217.55 L 365.99,218.57109"
                    style={{
                        fill: "#ffffff",
                        fillOpacity: 1,
                        fillRule: "evenodd",
                        stroke: "#000",
                        strokeWidth: 0.6,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                    }}
                />
                <path
                    d="M 155.67,210.88 C 158.81,170.04 179.46,151.43 175.83,118.76 C 173.68,99.45 156.29,114.09 161.96,132.20942"
                    style={{
                        fill: "none",
                        fillOpacity: 0.75,
                        fillRule: "evenodd",
                        stroke: "#000",
                        strokeWidth: 0.8,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                    }}
                />
                <path
                    d="M 163.97,266.61 C 161.90,274.15 157.78,269.35 154.13,269.92 C 160.13,278.43 135.74,276.66 142.98,289.79 C 141.38,299.95 143.83,307.26 155.01,309.37 C 158.63,304.47 162.10,300.46 162.91,309.50 C 169.37,307.62 177.86,296.86 176.99,307.92 C 181.37,301.25 195.24,297.77 195.07,290.58 C 202.09,291.32 209.63,297.93 215.56,288.40 C 221.51,281.36 222.83,288.33 222.92,293.77 C 230.15,300.53 238.45,290.15 245.97,288.43 C 244.03,279.72 254.84,286.88 253.25,279.16 C 252.22,274.10 286.90,275.59 281.91,270.65 C 267.00,255.86 243.72,278.14 240.35,276.72 C 236.98,275.29 233.26,276.16 237.96,274.00 C 242.67,271.84 231.22,275.39 221.69,272.00 C 212.15,268.61 205.17,272.90 204.54,281.34 C 197.35,276.88 196.84,281.95 185.65,285.06 C 192.78,274.64 173.05,282.29 173.05,282.29 C 173.05,282.29 176.49,270.87 170.31,272.37 C 173.63,266.41 169.95,264.57 169.95,264.57 C 169.95,264.57 171.77,259.45 163.97,266.61 z "
                    style={{
                        opacity: 0.11,
                        fill: "#000",
                        fillOpacity: 1,
                        fillRule: "evenodd",
                        stroke: "none",
                        strokeWidth: 0.7,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                    }}
                />
                <path
                    d="M 46.94,146.24 C 45.64,143.92 41.58,143.28 41.06,141.32 C 41.97,139.03 38.40,140.69 37.70,139.43 C 33.50,142.87 27.71,144.35 24.61,149.08 C 26.26,148.71 26.25,146.30 24.45,148.08 C 21.05,149.77 17.76,152.74 19.04,156.97 C 19.35,159.15 16.61,161.02 19.95,161.53 C 19.52,165.01 14.30,164.87 14.30,167.94 C 15.45,171.29 14.94,174.78 12.19,177.13 C 9.4813717,179.67 10.60,183.41 10.69,186.50 C 9.3328297,188.56 8.5633877,190.48 10.36,192.45 C 10.74,196.00 13.69,198.35 13.71,202.04 C 14.95,203.97 17.44,203.85 18.71,206.04 C 22.42,206.36 22.69,211.54 26.22,212.26 C 29.26,213.25 32.85,212.56 35.59,213.29 C 37.83,215.10 40.41,216.37 43.23,216.88 C 46.78,219.14 47.99,213.52 50.14,211.71 C 50.25,208.88 52.28,208.65 53.65,211.04 C 56.75,213.14 59.96,209.52 62.18,207.61 C 64.36,205.24 66.80,203.19 69.22,201.11 C 72.65,197.23 73.87,191.68 73.68,186.57 C 74.02,183.37 71.05,183.20 69.09,182.83 C 69.97,180.15 72.20,176.29 67.66,176.00 C 65.05,175.32 59.14,172.41 62.52,169.34 C 65.02,166.42 61.58,162.49 60.05,159.86 C 57.39,157.74 55.85,154.80 54.20,152.23 C 51.00,151.72 48.00,149.77 47.19,146.38 C 46.34,143.69 46.95,145.68 47.22,146.99878"
                    style={{
                        fill: "#ffffff",
                        fillOpacity: 1,
                        fillRule: "evenodd",
                        stroke: "#000",
                        strokeWidth: 0.8,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 4,
                    }}
                />
                <path
                    d="M 20.40,181.84 C 18.80,179.87 25.12,180.80 24.60,178.83 C 25.51,176.55 24.42,176.46 23.72,175.20 C 19.52,178.63 20.51,170.50 17.40,175.22 C 19.06,174.86 18.78,168.89 16.98,170.67 C 13.58,172.36 14.30,164.87 14.30,167.94 C 15.45,171.29 14.94,174.78 12.19,177.13 C 9.4813717,179.67 10.60,183.41 10.69,186.50 C 9.3328297,188.56 8.5633877,190.48 10.36,192.45 C 10.74,196.00 13.69,198.35 13.71,202.04 C 14.95,203.97 17.44,203.85 18.71,206.04 C 22.42,206.36 22.69,211.54 26.22,212.26 C 29.26,213.25 32.85,212.56 35.59,213.29 C 37.83,215.10 40.41,216.37 43.23,216.88 C 46.78,219.14 47.99,213.52 50.14,211.71 C 50.25,208.88 52.28,208.65 53.65,211.04 C 56.75,213.14 59.96,209.52 62.18,207.61 C 64.36,205.24 53.84,207.96 56.26,205.89 C 59.69,202.01 48.51,211.30 48.31,206.20 C 48.65,203.00 35.94,210.27 38.63,204.64 C 39.52,201.96 40.17,203.89 35.62,203.61 C 33.02,202.93 25.82,198.11 29.21,195.04 C 31.70,192.13 27.86,195.50 26.33,192.86 C 23.67,190.75 25.37,191.91 23.72,189.34 C 20.52,188.83 21.93,187.53 21.11,184.13 C 19.73,188.11 20.13,180.53 20.40,181.84 z "
                    style={{
                        opacity: 0.09,
                        fill: "#000",
                        fillOpacity: 1,
                        fillRule: "evenodd",
                        stroke: "none",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 4,
                    }}
                />
                <path
                    d="M 157.67,192.36 C 157.67,192.36 170.81,158.84 173.97,145.48 C 176.92,132.97 176.54,113.87 172.12,110.34 C 167.69,106.80 161.87,112.92 161.25,120.15 C 160.64,127.39 160.13,129.22 163.10,143.02 C 164.44,149.28 163.77,164.11 161.94,171.12 C 160.11,178.12 156.11,194.06 157.67,192.36 z "
                    style={{
                        fill: "#000",
                        fillOpacity: 0.22,
                        fillRule: "evenodd",
                        stroke: "none",
                        strokeWidth: 1,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                    }}
                />
            </g>
        </svg>
    );
}
